import {
  Button,
  ButtonTypes,
  FormCheckbox,
  FormTextField,
  FormPhoneFields,
  IAddressIndexed,
  IError,
  IPhoneIndexed,
  LoadingOverlay,
  TextFieldTypes,
  Vendors,
  countries,
  getPrimaryAddressFromProfile,
  getPrimaryPhoneNumberFromProfile,
  getSecondaryPhoneNumberFromProfile,
  getStatesByCountry,
  languages,
  months,
  useToast,
  phoneTypes,
  getMobilePhoneNumberFromProfile,
} from '@comptia-sso/core';
import ReportProblemOutlined from '@material-ui/icons/ReportProblemOutlined';
import { FormikProvider, useFormik } from 'formik';
import omit from 'lodash/omit';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

// Hooks.
import {
  PearsonActions,
  useCreatePearsonCandidate,
  useProfile,
  useRedirectToVendor,
  useTrackEvent,
  useUpdateProfileCandidate,
  useTrackOdpEvent,
} from 'hooks';

// Layouts.
import { ContentLayout } from 'layouts';

// Styles.
import styles from './ProfilePearsonView.module.scss';

// Validations.
import { getValidationSchema } from './validationSchema';
import { useNavigate } from 'react-router';

export const ProfilePearsonView = (): ReactElement => {
  const navigate = useNavigate();
  const trackEvent = useTrackEvent('Schedule Exam');
  const { t } = useTranslation();
  const [popToast] = useToast();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [profile] = useProfile();
  const [createPearsonCandidate] = useCreatePearsonCandidate();
  const [updateProfileCandidate] = useUpdateProfileCandidate(profile?.id);
  const trackOdpEvent = useTrackOdpEvent();
  const [redirectToVendor] = useRedirectToVendor(profile?.id);
  const hasPearsonAccount = useMemo(
    () => !!profile?.externalIds?.pearsonVue,
    [profile],
  );
  const primaryAddress: IAddressIndexed = useMemo(
    () => getPrimaryAddressFromProfile(profile) ?? ({} as IAddressIndexed),
    [profile],
  );
  const primaryPhoneNumber: IPhoneIndexed = useMemo(
    () => getPrimaryPhoneNumberFromProfile(profile) ?? ({} as IPhoneIndexed),
    [profile],
  );
  const mobilePhoneNumber: IPhoneIndexed = useMemo(
    () => getMobilePhoneNumberFromProfile(profile) ?? ({} as IPhoneIndexed),
    [profile],
  );
  const secondaryPhoneNumber: IPhoneIndexed = useMemo(
    () => getSecondaryPhoneNumberFromProfile(profile) ?? ({} as IPhoneIndexed),
    [profile],
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      allowConsentToGatherBirthInformation:
        profile?.allowConsentToGatherBirthInformation ?? false,
      allowConsentToSendSmsTextMessages:
        profile?.allowConsentToSendSmsTextMessages ?? false,
      allowCredentialReleaseToThirdParty:
        profile?.allowCredentialReleaseToThirdParty ?? false,
      alternateEmail: profile?.alternateEmail || '',
      birthDay: profile?.birthDay ?? null,
      birthMonth: profile?.birthMonth ?? null,
      birthYear: profile?.birthYear ?? null,
      compTIAId: profile?.compTIAId || '',
      email: profile?.email || '',
      givenName: {
        firstName: profile?.givenName?.firstName || '',
        lastName: profile?.givenName?.lastName || '',
        middleName: profile?.givenName?.middleName || '',
        suffix: profile?.givenName?.suffix || '',
      },
      isUsCitizenOlderThanEighteenYears:
        profile?.isUsCitizenOlderThanEighteenYears ?? false,
      mobilePhoneNumber: {
        countryCode: mobilePhoneNumber?.countryCode ?? '1',
        index: mobilePhoneNumber?.index,
        isPrimary: mobilePhoneNumber?.isPrimary ?? false,
        phone: mobilePhoneNumber?.phone || '',
        phonetype: mobilePhoneNumber.phoneType || phoneTypes.Cellphone,
      },
      preferences: {
        language: profile?.preferences?.language || '',
      },
      preferredName: {
        firstName: profile?.preferredName?.firstName || '',
        lastName: profile?.preferredName?.lastName || '',
        middleName: profile?.preferredName?.middleName || '',
        suffix: profile?.preferredName?.suffix || '',
      },
      primaryAddress: {
        address1: primaryAddress?.address1 || '',
        address2: primaryAddress?.address2 || '',
        address3: primaryAddress?.address3 || '',
        city: primaryAddress?.city || '',
        country: primaryAddress?.country || 'USA',
        index: primaryAddress?.index,
        isPrimary: primaryAddress?.isPrimary ?? true,
        postalCode: primaryAddress?.postalCode || '',
        state: primaryAddress?.state || '',
      },
      primaryPhoneNumber: {
        countryCode: primaryPhoneNumber?.countryCode ?? '1',
        index: primaryPhoneNumber?.index,
        isPrimary: primaryPhoneNumber?.isPrimary ?? true,
        phone: primaryPhoneNumber?.phone || '',
        phonetype: primaryPhoneNumber.phoneType || phoneTypes.Home,
      },
      privacyPolicyAccepted: profile?.privacyPolicyAccepted ?? false,
      secondaryPhoneNumber: {
        countryCode: secondaryPhoneNumber?.countryCode ?? '1',
        index: secondaryPhoneNumber?.index,
        isPrimary: secondaryPhoneNumber?.isPrimary ?? false,
        phone: secondaryPhoneNumber?.phone || '',
        phonetype: secondaryPhoneNumber.phoneType || phoneTypes.Home,
      },
    },
    onSubmit: async (values) => {
      setIsSubmitting(true);

      trackEvent('Submitted');
      trackOdpEvent('form', 'Schedule Exam', '');
      try {
        if (hasPearsonAccount) {
          await updateProfileCandidate(values);
          await redirectToVendor(Vendors.PearsonVue, {
            pearsonAction: PearsonActions.ViewExamCatalog,
          });
        } else {
          const { secondaryPhoneNumber, ...rest } = values;
          const createValues = omit(
            {
              id: profile?.id,
              ...rest,
              ...(secondaryPhoneNumber.phone ? { secondaryPhoneNumber } : {}), // Only include secondaryPhoneNumber if entered.
            },
            [
              'primaryAddress.index',
              'primaryPhoneNumber.index',
              'secondaryPhoneNumber.index',
            ], // Remove index values for create candidate.
          );
          await createPearsonCandidate(createValues);
          await redirectToVendor(Vendors.PearsonVue, {
            pearsonAction: PearsonActions.ViewExamCatalog,
          });
        }
      } catch (error) {
        setIsSubmitting(false);
        popToast((error as IError)?.message || t('Toast.Error.Default'));
      }
    },
    validateOnChange: false,
    validationSchema: getValidationSchema(t, profile),
  });

  const states = useMemo(
    () => getStatesByCountry(formik.values.primaryAddress.country),
    [formik.values.primaryAddress.country],
  );

  const handleCitizenConfirmationChange = useCallback((e) => {
    if (!e.target.checked) {
      formik.setFieldValue('allowConsentToGatherBirthInformation', false);
      formik.setFieldValue('birthMonth', null);
      formik.setFieldError('birthMonth', undefined);
      formik.setFieldValue('birthYear', null);
      formik.setFieldError('birthYear', undefined);
    }
  }, []);

  const handleBirthdayConsentChange = useCallback((e) => {
    if (!e.target.checked) {
      formik.setFieldValue('birthDay', null);
      formik.setFieldValue('birthMonth', null);
      formik.setFieldError('birthMonth', undefined);
      formik.setFieldValue('birthYear', null);
      formik.setFieldError('birthYear', undefined);
    }
  }, []);

  useEffect(() => {
    if (profile && profile?.isBlocked) {
      (async () => {
        navigate('/');
        return <LoadingOverlay isOpen text={t('Loading.Default')} />;
      })();
    }
  }, [profile]);

  const validationErrorMessage = () => {
    return (
      <div className={styles.message}>
        <ReportProblemOutlined className={styles.icon} />
        <span>{t('PearsonDemographic.MissingFields')}</span>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>Schedule Exam</title>
      </Helmet>
      <ContentLayout
        subtitle={t('PearsonDemographic.Subheading')}
        title={t('PearsonDemographic.Heading')}
      >
        <div className={styles.description}>
          <p>
            <small>{t('PearsonDemographic.NoReimbursement')}</small>
          </p>
          <p>
            <small>
              <strong>{t('PearsonDemographic.NameInEnglish')}</strong>
            </small>
          </p>
          {hasPearsonAccount && (
            <p>
              <small
                dangerouslySetInnerHTML={{ __html: t('EditProfile.LegalName') }}
              />
            </p>
          )}
        </div>
        <form noValidate onSubmit={formik.handleSubmit}>
          <FormikProvider value={formik}>
            <fieldset>
              <small className={styles.required}>
                {t('PearsonDemographic.Required')}
              </small>
              <legend>{t('PearsonDemographic.LegalName')}</legend>
              <div className={styles.grid}>
                <FormTextField
                  disabled={hasPearsonAccount}
                  label={t('Profile.GivenName.FirstName')}
                  maxLength={30}
                  name="givenName.firstName"
                  required
                />
                <FormTextField
                  disabled={hasPearsonAccount}
                  label={t('Profile.GivenName.MiddleName')}
                  maxLength={30}
                  name="givenName.middleName"
                />
                <FormTextField
                  disabled={hasPearsonAccount}
                  label={t('Profile.GivenName.LastName')}
                  maxLength={50}
                  name="givenName.lastName"
                  required
                />
                <FormTextField
                  disabled={hasPearsonAccount}
                  className={styles.suffix}
                  label={t('Profile.GivenName.Suffix')}
                  name="givenName.suffix"
                  maxLength={10}
                />
              </div>
            </fieldset>
            <fieldset>
              <legend>{t('PearsonDemographic.PreferredName')}</legend>
              <div className={styles.grid}>
                <FormTextField
                  label={t('Profile.PreferredName.FirstName')}
                  maxLength={30}
                  name="preferredName.firstName"
                />
                <FormTextField
                  label={t('Profile.PreferredName.MiddleName')}
                  maxLength={30}
                  name="preferredName.middleName"
                />
                <FormTextField
                  label={t('Profile.PreferredName.LastName')}
                  maxLength={50}
                  name="preferredName.lastName"
                />
                <FormTextField
                  className={styles.suffix}
                  label={t('Profile.PreferredName.Suffix')}
                  name="preferredName.suffix"
                  maxLength={10}
                />
              </div>
            </fieldset>
            <fieldset>
              <div className={styles.grid}>
                <FormCheckbox
                  label={t('Profile.IsUsCitizenOlderThanEighteenYears')}
                  name="isUsCitizenOlderThanEighteenYears"
                  onChange={handleCitizenConfirmationChange}
                />
                <FormCheckbox
                  disabled={
                    !formik.values.isUsCitizenOlderThanEighteenYears &&
                    !formik.values.allowConsentToGatherBirthInformation
                  }
                  label={t('Profile.AllowConsentToGatherBirthInformation')}
                  name="allowConsentToGatherBirthInformation"
                  onChange={handleBirthdayConsentChange}
                />
                <FormTextField
                  className={styles['birth-month']}
                  disabled={!formik.values.allowConsentToGatherBirthInformation}
                  label={t('Profile.BirthMonth')}
                  name="birthMonth"
                  required={formik.values.allowConsentToGatherBirthInformation}
                  select
                >
                  {months.map((month, index) => (
                    <option
                      key={index}
                      value={String(index + 1).padStart(2, '0')}
                    >
                      {month}
                    </option>
                  ))}
                </FormTextField>
                <FormTextField
                  className={styles['birth-year']}
                  disabled={!formik.values.allowConsentToGatherBirthInformation}
                  label={t('Profile.BirthYear')}
                  maxLength={4}
                  name="birthYear"
                  required={formik.values.allowConsentToGatherBirthInformation}
                  type={TextFieldTypes.Number}
                />
              </div>
            </fieldset>
            <fieldset>
              <legend>{t('PearsonDemographic.ContactInformation')}</legend>
              <div className={styles.grid}>
                <FormPhoneFields
                  countryCodeClassName={styles.code}
                  countryCodeName="primaryPhoneNumber.countryCode"
                  countryCodeLabel={t('Profile.PhoneNumber.CountryCode')}
                  phoneClassName={styles.phone}
                  phoneName="primaryPhoneNumber.phone"
                  phoneLabel={t('Profile.PhoneNumber.Phone')}
                  required
                  phoneType="primaryPhoneNumber.phonetype"
                />
                <FormPhoneFields
                  countryCodeClassName={styles.code}
                  countryCodeName="mobilePhoneNumber.countryCode"
                  countryCodeLabel={t('Profile.MobilePhoneNumber.CountryCode')}
                  phoneClassName={styles.phone}
                  phoneName="mobilePhoneNumber.phone"
                  phoneLabel={t('Profile.MobilePhoneNumber.Phone')}
                  phoneType="mobilePhoneNumber.phonetype"
                  required={formik.values.allowConsentToSendSmsTextMessages}
                />
                <FormCheckbox
                  label={t('Profile.AllowConsentToSendSmsTextMessages')}
                  name="allowConsentToSendSmsTextMessages"
                />
                <FormTextField
                  key={`countries-${countries?.length}`}
                  label={t('Profile.Address.Country')}
                  name="primaryAddress.country"
                  onChange={() => {
                    formik.setFieldValue('primaryAddress.state', '');
                  }}
                  required
                  select
                >
                  {countries?.map((country) => (
                    <option
                      key={country.abbreviation}
                      value={country.abbreviation}
                    >
                      {country.name}
                    </option>
                  ))}
                </FormTextField>
                <FormTextField
                  label={t('Profile.Address.Address1')}
                  maxLength={40}
                  name="primaryAddress.address1"
                  required
                />
                <FormTextField
                  label={t('Profile.Address.Address2')}
                  maxLength={40}
                  name="primaryAddress.address2"
                />
                <FormTextField
                  label={t('Profile.Address.Address3')}
                  maxLength={40}
                  name="primaryAddress.address3"
                />
                <FormTextField
                  label={t('Profile.Address.City')}
                  maxLength={32}
                  name="primaryAddress.city"
                  required
                />
                {states?.length > 0 && (
                  <FormTextField
                    key={`countries-${states?.length}`}
                    label={t('Profile.Address.State')}
                    name="primaryAddress.state"
                    required={['USA', 'CAN'].includes(
                      formik.values.primaryAddress.country,
                    )}
                    select
                  >
                    {states?.map((state) => (
                      <option
                        key={state.abbreviation}
                        value={state.abbreviation}
                      >
                        {state.name}
                      </option>
                    ))}
                  </FormTextField>
                )}
                <FormTextField
                  className={styles.zip}
                  label={t('Profile.Address.PostalCode')}
                  maxLength={16}
                  name="primaryAddress.postalCode"
                  required={['USA', 'CAN'].includes(
                    formik.values.primaryAddress.country,
                  )}
                />
                <FormTextField
                  label={t('Profile.Preferences.Language')}
                  name="preferences.language"
                  select
                >
                  {languages.map((language) => (
                    <option
                      key={language.abbreviation}
                      value={language.abbreviation}
                    >
                      {language.name}
                    </option>
                  ))}
                </FormTextField>
                <FormTextField
                  label={t('Profile.Email')}
                  name="email"
                  disabled
                  required
                  type={TextFieldTypes.Email}
                />
              </div>
            </fieldset>
            <fieldset>
              <legend>{t('EditProfile.AlternateContactInformation')}</legend>
              <div className={styles.grid}>
                <p className={styles.alternate}>
                  <small>{t('EditProfile.AlternateContactSubheading')}</small>
                </p>
                <FormTextField
                  label={t('Profile.AlternateEmail')}
                  maxLength={255}
                  name="alternateEmail"
                  type={TextFieldTypes.Email}
                />
                <FormPhoneFields
                  countryCodeClassName={styles.code}
                  countryCodeName="secondaryPhoneNumber.countryCode"
                  countryCodeLabel={t(
                    'Profile.AlternatePhoneNumber.CountryCode',
                  )}
                  phoneClassName={styles.phone}
                  phoneName="secondaryPhoneNumber.phone"
                  phoneLabel={t('Profile.AlternatePhoneNumber.Phone')}
                  phoneType="secondaryPhoneNumber.phonetype"
                />
              </div>
            </fieldset>
            <fieldset>
              <div className={styles.grid}>
                <FormCheckbox
                  label={t('Profile.AllowCredentialReleaseToThirdParty')}
                  name="allowCredentialReleaseToThirdParty"
                />
              </div>
            </fieldset>
            <fieldset>
              <div className={styles.grid}>
                <FormCheckbox
                  className={styles.checkbox}
                  label={t('Profile.PrivacyPolicyAccepted')}
                  name="privacyPolicyAccepted"
                />
              </div>
            </fieldset>
            {!isSubmitting && !formik.isValid && validationErrorMessage()}
            <Button className={styles.button} type={ButtonTypes.Submit}>
              {t('PearsonDemographic.Cta')}
            </Button>
          </FormikProvider>
        </form>
        <LoadingOverlay isOpen={isSubmitting} text={t('Loading.Default')} />
      </ContentLayout>
    </>
  );
};
