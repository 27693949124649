/* eslint-disable sort-keys */
export const translation = {
  Button: {
    Edit: 'Edit',
    Cancel: 'Cancel',
  },

  ComingSoon: {
    ReleaseDate: 'Coming Soon!',
    RedeemAccessKeys:
      'Soon you will be able to redeem access keys for your new CompTIA training products from this page. Check back for updates!',
    AccessYourTraining:
      'Soon you will be able to access all your CompTIA training from this page. Check back for updates!',
    BrowseTraining: 'Browse Training',
  },

  Loading: {
    Default: 'Please Wait',
    AddressVerification: 'Verifying Address',
    RedeemingAccessKey: 'Redeeming Access Key',
    UpdatingProfile: 'Updating Profile',
    UpdatingEmail: 'Updating Email',
    UpdatingPassword: 'Updating Password',
    SendingVerificationCode: 'Sending Verification Code',
    ResendingVerificationCode: 'Sending New Verification Code',
  },

  Toast: {
    Action: {
      Dismiss: 'Got It',
    },
    Error: {
      Default: 'An unexpected error ocurred. Please try again later.',
    },
    Success: {
      EditProfile: 'Your profile was successfully updated.',
      EditEmail:
        'Your email has been successfully changed. You are being redirected to the login page to sign in with your new email.',
      EditPassword: 'Your password was successfully updated.',
      VerificationCodeSent:
        'A verification code has been sent to your new email. Please enter it on the next step to change your email.',
    },
  },

  Validation: {
    AlternateEmail: 'Cannot be your alternate email',
    CurrentEmail: 'Cannot be your current email',
    CurrentPassword: 'Must be different than current password',
    InvalidAddress: 'Invalid address',
    InvalidCity: 'Invalid city',
    InvalidCountry: 'Invalid country',
    InvalidCountryCode: 'Invalid country code',
    InvalidName: 'Invalid name',
    InvalidPassword:
      '10-24 characters, containing 3 out of 4 of the following: Lowercase characters, uppercase characters, digits (0-9), and one or more of the following symbols: @ # $ % ^ & * - _ + = [] {} | \\ : \' , ? / ` ~ " () ; ! .',
    InvalidPhoneNumber: 'Invalid phone number',
    InvalidPostalCode: 'Invalid postal code',
    InvalidState: 'Invalid state',
    MaximumLength: 'Exceeds maximum length',
    PasswordsMatch: 'Passwords do not match',
    PrimaryEmail: 'Must not match primary email',
    PrimaryPhoneNumber: 'Must not match primary phone number',
    Required: 'Required',
    ValidEmail: 'Must be a valid email',
    ValidBirthDay: 'Invalid birth day',
    validMobilePhoneNumber:
      'Please enter a mobile number to receive SMS messages',
  },

  PearsonError: {
    Button: 'Go Back to the Homepage',
    Default:
      'An unexpected error has ocurred in the Pearson VUE system. Please contact the call center for assistance.',
    CandidateNotFound:
      'The Pearson VUE candidate was not found. Please contact the call center for assistance.',
    ClientNotSupported:
      'The Pearson VUE client is not supported. Please contact the call center for assistance.',
    InvalidPayload:
      'The Pearson VUE payload authentication hash did not match. Please contact the call center for assistance.',
    MissingRequiredParameters:
      'A required Pearson VUE payload parameter was not provided by the client. Please contact the call center for assistance.',
    RegistrationNotFound:
      'The Pearson VUE exam registration ID was not found. Please contact the call center for assistance.',
    SessionTimeout:
      'The Pearson VUE request has timed out. Please contact the call center for assistance.',
    Temporary:
      'The Pearson VUE website is experiencing a temporary planned outage. Please contact the call center for assistance.',
    VueWideBlock:
      'Your access to the Pearson VUE website is restricted at this time. This could be due to a variety of circumstances, including past payment issues, etc.<br><br> Please contact Pearson VUE\'s customer service team directly at 877-551-7587 to inquire about and resolve this restriction. You may also find other Pearson VUE contact methods (including chat and email <a href="https://home.pearsonvue.com/comptia/contact/proctored" style="outline: none;" target="_blank">here</a>.)',
  },

  FullfillmentBanner: {
    'Title-1':
      'You have a certification kit ready to be mailed. Click here to verify your address.',
    Button: 'Verify Address',
  },

  AddressVerificationModal: {
    Title:
      'Once you have verified your address, your fulfillment will be sent.',
    SuccessMessage:
      'Your address has been verified and your certification kit will be mailed.',
    Button: 'Verify Address',
  },

  ProfileMenu: {
    CompTIA: 'CompTIA ID',
    'Nav-1': 'My profile',
    'Nav-2': 'My login credentials',
    'Nav-3': 'My training products',
    Button: 'Sign Out',
    'Link-1': 'Legal',
  },

  Navigation: {
    'Link-1': 'Schedule Exam',
    'Link-2': 'Manage Exams',
    'Link-3': 'Manage Certifications',
    'Link-4': 'Access Training',
    'Link-5': 'Redeem Access Keys',
    'Link-Return': 'Return to Home',
    'Link-GoBack': 'Go Back',
  },

  WelcomeHeading: 'Welcome',

  ExamRegistration: {
    Accommodations:
      '<b><a target="_blank" href="https://home.pearsonvue.com/Test-takers/Accommodations.aspx">Request Test Accommodations</a></b>',
    Title: 'Schedule your exam',
    Content: 'Take your exam in person or online.',
    'List-1': 'Select a test center or online',
    'List-2': 'Choose your date and time',
    'List-3': 'Review exam policies',
    Button: 'Schedule Now',
    'Button-2': 'Learn More',
    Blocked:
      'Your account has been temporarily suspended from registering for CompTIA certification exams. For questions about this suspension, please contact <a href="mailto:security@comptia.org">security@comptia.org</a> and provide your CompTIA ID number. Thank you.',
  },

  PearsonVueDashboard: {
    Title: 'Manage your exams',
    Content: 'Plan upcoming exams and get your results.',
    'List-1': 'Cancel or reschedule your exam',
    'List-2': 'Launch your scheduled online exam',
    'List-3': 'View and download your score reports',
    Button: 'My Exams',
    'Button-2': 'View Testing Options',
  },

  CertMetricsAccount: {
    Title: 'Access your certifications',
    Content: 'View and manage your certifications',
    'List-1': 'View certification status',
    'List-2': 'Claim digital badges',
    'List-3': 'Pay CE fees and submit CEUs',
    'List-4': 'Download a verifiable PDF of your certificate',
    Button: 'Go to My Certifications',
    'Button-2': 'Browse Certifications',
  },

  AccessYourTraining: {
    Title: 'Access your training',
    Content: 'Open your study guides, labs and eLearning courses.',
    Content2:
      'Don\'t see your course(s) listed here? In some cases, your course(s) may be accessed outside of this login portal. <a href="https://help.comptia.org/hc/en-us/articles/13934951396500-How-Do-I-Access-Training-Products-Through-My-CompTIA-SSO-Account" rel="noopener noreferrer" target="_blank">Learn More</a>.',
    Content3:
      'To access the most recent CertMaster Learn, Perform, and Labs courses, along with legacy TestOut "Pro" courses. <a href="https://labsimapp.testout.com/" rel="noopener noreferrer" target="_blank">Sign in here</a>.',
    Button: 'Get Started',
    'Link-1': 'CertMaster Labs',
    'Link-2': 'CertMaster Practice',
    'Link-3': 'Student & Instructor Guides',
    'Link-4': 'CertMaster Learn',
    'Link-5': 'Self-Paced Study Guides',
    'Link-6': 'CertMaster CE',
    'Link-7': 'CompTIA Labs',
    'Link-8': 'CompTIA Direct - Live Online Training',
    'Link-9': 'On Demand Training',
  },

  RedeemInformation: {
    Content:
      "For store purchases, access keys are sent by email following order completion, and may also be retrieved from your store account's Order History. For instructor-led training, access keys are typically distributed by an instructor or institution admin.",
    Title: 'Where do I find the access code?',
  },

  RedeemModule: {
    Label: 'Enter Code',
    Button: 'Redeem Now',
    Content:
      'Enter your training product access key. If you have multiple access keys, enter and redeem them one at a time.',
    Title: 'Redeem access keys',
    NoProducts: 'This access key has reached its maximum redemption limit.',
    Details: {
      Content:
        'Please enter your Company and Country to complete the access key redemption process.',
      Cta: 'Continue',
    },
    Demograpghics: {
      Content:
        'Please fill out the following information to complete the access key redemption process.',
      Cta: 'Continue',
    },
    Products: {
      Content:
        'There are multiple products associated with this access key, which product would you like to redeem?',
      Label: 'Select Product',
      Cta: 'Continue',
    },
    Success: {
      Content:
        'You have successfully redeemed your access key. You may now access your new training product or view all your products.',
      ContentPopuliWaitlist:
        'You have successfully redeemed the access key! An email containing the course enrollment agreement will be sent to you shortly. Once signed by you and a school official, you will have access to the course content.',
      ContentPopuliAutoEnrolled:
        'You have successfully redeemed the access key! Please allow 20 minutes for all systems to synchronize, and proceed to your course by following the link in the "Access your training" section of this website.',
      Cta: 'Access my new training',
      ViewAll: 'View All My Products',
      LOT: 'LOT',
    },
  },

  NeedHelp: {
    Title: 'Need help?',
    Content: 'Review FAQs and contact our customer service team.',
    'List-1': 'Changing the legal first name and last name on your account',
    'List-2': 'Merging multiple accounts under a single login',
    'List-3': 'Releasing your pending certification kit for fulfillment',
    Button: 'Get Help',
  },

  ExploreLaunchpad: {
    Title: 'CompTIA Launchpad is the single source for all your exam materials',
    Content:
      'Your single login for your CompTIA training and certification journey.',
    Button: 'Explore Launchpad',
  },

  OnboardingSecure: {
    Button: 'Learn More',
    Title: 'Secure',
    Content:
      'CompTIA Launchpad is the easy and secure way to access all your products, services, tests, certifications and more.',
    'Title-2': 'Secure',
    'Content-2': 'Waiting on back of card final details.',
  },

  OnboardingConnected: {
    Button: 'Learn More',
    Title: 'Connected',
    Content:
      'CompTIA Launchpad allows you to see all your past, present, and upcoming tests. All conveniently linked to one account.',
    'Title-2': 'Connected',
    'Content-2': 'Waiting on back of card final details.',
  },

  OnboardingSimple: {
    Button: 'Learn More',
    Title: 'Simple',
    Content:
      'Easy log-in, industry-leading encryption, and you manage it all on the CompTIA Launchpad.',
    'Title-2': 'Simple',
    'Content-2': 'Waiting on back of card final details.',
  },

  'FooterColumn-1': {
    Title: 'Contact Us',
    'Link-1': 'Help Center',
    'Link-2': 'Merge Account Request',
    'Link-3': 'Name Change Request',
  },

  'FooterColumn-1-Links': {
    'Link-1': '/help',
    'Link-2':
      '/help?returnto=https://help.comptia.org/hc/en-us/articles/11184936256020-How-Can-I-Get-Multiple-Accounts-Merged',
    'Link-3':
      '/help?returnto=https://help.comptia.org/hc/en-us/articles/14075048104596-How-Do-I-Correct-or-Change-My-Name-on-My-Account',
  },

  'FooterColumn-2': {
    Title: 'Certifications',
    'Link-1': 'CompTIA Certifications',
    'Link-2': 'Training Products',
    'Link-3': 'Continuing Education',
  },

  'FooterColumn-3': {
    Title: 'Store',
    'Link-1': 'CompTIA Store',
  },

  Profile: {
    Address: {
      Address1: 'Address 1',
      Address2: 'Address 2',
      Address3: 'Address 3',
      AddressType: 'Address Type',
      City: 'City',
      Country: 'Country',
      PostalCode: 'Zip Code',
      State: 'State/Province',
    },
    AllowCompTIAMarketingContact:
      "Please keep me informed of special promotions, discounts, new product announcements and other offerings for CompTIA's products and services including association updates. I understand that my information on record in CompTIA's secured database will be used to evaluate the products and services that may best fit my needs. I reserve the right to change my opt-in selections at any time.",
    AllowCompTIAPartnerMarketingContact:
      "I would like to receive information from CompTIA's partners on any special promotions, discounts, new product announcements and other offerings that may benefit me. I reserve the right to change my opt-in selection at any time.",
    AllowConsentToGatherBirthInformation:
      'Do you consent to CompTIA collecting your month and year of birth?',
    AllowConsentToSendSmsTextMessages:
      'I consent to receive SMS text messages from CompTIA and affiliates for updates, promotions, and important information.',
    AllowConsentToGatherBirthInformation2:
      'I consent to CompTIA collecting my day, month and year of birth.',
    AllowCredentialReleaseToThirdParty:
      'I authorize CompTIA to release information pertaining to my credentials to third party organizations, government entities and academic institutions. I understand that in no event shall CompTIA, its officers, directors or employees be liable for any special, indirect, incidental or consequential damages or any damages resulting from the release of this information. I reserve the right to change my opt-in selections at any time.',
    AlternateEmail: 'Alternate Email',
    AlternatePhoneNumber: {
      CountryCode: 'Country code',
      Phone: 'Alternate Phone',
    },
    BirthMonth: 'Birth Month',
    BirthYear: 'Birth Year',
    BirthDay: 'Birth Day',
    Company: 'Company',
    Email: 'Email',
    GivenName: {
      FirstName: 'First Name',
      Heading: 'Legal Name',
      LastName: 'Last Name',
      MiddleName: 'Middle Name',
      Suffix: 'Suffix',
      Title: 'Title',
    },
    IsUsCitizenOlderThanEighteenYears:
      'Are you a US Citizen and at least 18 years of age?',
    IsOlderThanEighteenYears: 'Are you at least 18 years of age?',
    MobilePhoneNumber: {
      CountryCode: 'Country code',
      Phone: 'Mobile Phone',
    },
    PhoneNumber: {
      CountryCode: 'Country code',
      Phone: 'Phone',
    },
    Preferences: {
      Language: 'Correspondence Language',
    },
    PreferredName: {
      FirstName: 'First Name',
      FirstNameQualified: 'Preferred First Name',
      Heading: 'Preferred Name',
      LastName: 'Last Name',
      LastNameQualified: 'Preferred Last Name',
      MiddleName: 'Middle Name',
      Suffix: 'Suffix',
      Title: 'Title',
    },
    PrivacyPolicyAccepted:
      'I agree to CompTIA’s <a href="https://www.comptia.org/legal">Privacy Policy and Terms & Conditions</a>',
  },

  EditAccount: {
    Subheading: 'My login credentials',
    Required: 'Required*',
    Email: {
      Tab: 'My Email',
      Heading: 'Primary Email Address',
      EmailAddress: 'Email Address',
      ConfirmCurrentPassword: 'Confirm Current Password',
      Disclaimer:
        'You will be redirected to the login page to sign in with your new email.',
      VerificationInstructions:
        'A verification code will be sent to your new email.',
      Social: 'This is a social account. The email cannot be changed.',
      SendVerificationCode: 'Send Verification Code',
      ResendVerificationCode: 'Send New Verification Code',
      VerifyCode: 'Verify Code',
      VerificationCode: 'Verification Code',
      NewPassword: 'New Password',
      Submit: 'Submit',
    },
    Password: {
      Tab: 'My Password',
      Heading: 'Password',
      CurrentPassword: 'Current Password',
      NewPassword: 'New Password',
      ConfirmNewPassword: 'Confirm New Password',
      Social: 'This is a social account. The password cannot be changed.',
    },
    Cta: 'Save',
  },

  EditProfile: {
    Subheading: 'My profile',
    LegalName:
      'Please submit a <a href="https://help.comptia.org/hc/en-us/articles/14075048104596-How-Do-I-Correct-or-Change-My-Name-on-My-Account" rel="noopener noreferrer" target="_blank">name change request</a> to update or correct your legal name.',
    PersonalInformation: 'Personal Information',
    ContactInformation: 'Contact Information',
    AlternateContactInformation: 'Alternate Contact Information',
    AlternateContactSubheading:
      'We encourage you to provide an alternate email and alternate phone for future account recovery options.',
    Cta: 'Save',
  },

  PearsonDemographic: {
    Heading: 'Schedule exam',
    Subheading:
      'Please confirm or complete the information below to begin the exam scheduling process.',
    YouMustEnter:
      'You must enter your legal name exactly as it appears on the identification you will present at the test center.',
    NameInEnglish: '',
    NoReimbursement:
      'IMPORTANT NOTE: Your legal name below must match the first name and last name as it appears on the ID you will present for exam check-in. If they do not match, you will not be able to take your exam and your exam fee will not be refunded.',
    Required: 'Required*',
    LegalName: 'Legal Name',
    PreferredName: 'Preferred Name',
    ContactInformation: 'Contact Information',
    Cta: 'Save Updates',
    MissingFields: 'You have missed required fields.',
  },

  TrainingProducts: {
    Subheading: 'My Training Products',
    HeadingTag: 'Entitlements',
    ProductType: 'Product Type',
    PersonalAccount: 'Personal Account',
    BusinessAccount: 'Business Account',
    ExpiresOn: 'Expires on',
    ExpiredOn: 'Expired on',
    NoProducts: 'You have not redeemed any training products.',
    WaitingOnAgreement: 'Pending sync & agreement completion if applicable',
  },

  NotFound: {
    Heading: '404 Not Found',
    Subheading: "Can't find what you're looking for?",
    Button: 'Go to the launchpad homepage',
  },
};
